import { formatDistance, intervalToDuration } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import roLocale from 'date-fns/esm/locale/ro';

export const getDateDifference = (end, start = 'now') => {
	const _start = start === 'now' ? new Date() : utcToZonedTime(start, 'UTC');
	const _end = end === 'now' ? new Date() : utcToZonedTime(end, 'UTC');

	try {
		if (_end < _start) {
			intervalToDuration({ start: _end, end: _start });

			return {
				distance: formatDistance(_start, _end, { locale: roLocale }),
				expired: true,
			};
		}

		intervalToDuration({ start: _start, end: _end });
		return {
			distance: formatDistance(_end, _start, { locale: roLocale }),
			expired: false,
		};
	} catch (error) {
		return null;
	}
};

export const formatDate = (date, fmt = 'dd.MM.yyyy / HH:mm', tz = 'UTC') =>
	format(utcToZonedTime(date, tz), fmt, { timeZone: tz });

const isPrimitive = value => value !== Object(value);

const isPlainObject = value => value != null && [null, Object.prototype].includes(Object.getPrototypeOf(value));

// Each type corresponds to a particular comparison algorithm
const getType = value => {
	if (isPrimitive(value)) return 'primitive';
	if (Array.isArray(value)) return 'array';
	if (value instanceof Map) return 'map';
	if (isPlainObject(value)) return 'plainObject';
	throw new Error(`deeply comparing an instance of type ${value.constructor?.name} is not supported.`);
};

export function isEqual(value1, value2) {
	const type = getType(value1);
	if (type !== getType(value2)) {
		return false;
	}

	if (type === 'primitive') {
		return value1 === value2 || (Number.isNaN(value1) && Number.isNaN(value2));
	} else if (type === 'array') {
		return value1.length === value2.length && value1.every((iterValue, i) => isEqual(iterValue, value2[i]));
	} else if (type === 'map') {
		// In this particular implementation, map keys are not
		// being deeply compared, only map values.
		return (
			value1.size === value2.size &&
			[...value1].every(([iterKey, iterValue]) => {
				return value2.has(iterKey) && isEqual(iterValue, value2.get(iterKey));
			})
		);
	} else if (type === 'plainObject') {
		const value1AsMap = new Map(Object.entries(value1));
		const value2AsMap = new Map(Object.entries(value2));
		return (
			value1AsMap.size === value2AsMap.size &&
			[...value1AsMap].every(([iterKey, iterValue]) => {
				return value2AsMap.has(iterKey) && isEqual(iterValue, value2AsMap.get(iterKey));
			})
		);
	} else {
		throw new Error(`Unreachable`);
	}
}
