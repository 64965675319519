import { Grid } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';

import MainButton from '../components/common/basic/main-button';
import BackButton from '../components/common/basic/back-button';
import MainLayout from '../components/common/main-layout';
import ManoperaTeslaButton from '../components/common/basic/manopera-tesla-button';
import { useAuthState } from '../utils/firebase';

const OrderPage = () => {
	const navigate = useNavigate();
	const { user } = useAuthState();
	const { orderId } = useParams();

	const handlePhotos = () => {
		navigate(`/${orderId}/photos`);
	};

	const handleAddons = () => {
		navigate(`/${orderId}/addons`);
	};

	const handleFileStatus = () => {
		navigate(`/${orderId}/file-status`);
	};

	const handleBack = () => {
		navigate('/');
	};

	return (
		<MainLayout>
			<Grid gap={6} minW={'400px'}>
				<MainButton onClick={handlePhotos}>Poze</MainButton>
				<MainButton onClick={handleAddons}>Suplimentari</MainButton>
				<MainButton onClick={handleFileStatus}>Status dosar</MainButton>
				{user.level > 2 && (
					<>
						<ManoperaTeslaButton orderId={orderId} type={'spalatorie'} />
						<ManoperaTeslaButton orderId={orderId} type={'pdi'} />
					</>
				)}
				<BackButton onClick={handleBack}>Inapoi la pagina principala</BackButton>
			</Grid>
		</MainLayout>
	);
};

export default OrderPage;
