import { useRef } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useQueryClient } from 'react-query';
import { useDeleteAddonMutation } from '../../state/server-side/mutations/addons';

const DeleteButton = ({ addon }) => {
	const { orderId, addonId, type, part } = addon;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = useRef();
	const queryClient = useQueryClient();
	const mutation = useDeleteAddonMutation();

	const handleDelete = () => {
		mutation.mutate(
			{ orderId, addonId },
			{
				onSuccess: () => {
					queryClient.invalidateQueries(['addons', orderId]);
					onClose();
				},
			}
		);
	};

	return (
		<>
			<Button size={'sm'} onClick={onOpen}>
				Sterge
			</Button>

			<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Sterge suplimentare
						</AlertDialogHeader>

						<AlertDialogBody>
							Esti sigur ca vrei sa stergi{' '}
							<Text display={'inline-block'} fontWeight={'bold'}>
								{type} {part}
							</Text>
							?
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose}>
								Renunta
							</Button>
							<Button colorScheme="red" onClick={handleDelete} ml={3}>
								Sterge
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

export default DeleteButton;
