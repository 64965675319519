import { Button } from '@chakra-ui/react';
import { BsArrowLeft } from 'react-icons/bs';

const BackButton = ({ children, ...props }) => {
  return (
    <Button
      size={'lg'}
      colorScheme={'blue'}
      variant="ghost"
      leftIcon={<BsArrowLeft />}
      {...props}
    >
      {children}
    </Button>
  );
};

export default BackButton;
