import { getAuth } from '@firebase/auth';
import { useMutation, useQuery } from 'react-query';
import { doc, getDoc, updateDoc } from '@firebase/firestore';
import { firestore } from '../../../utils/firebase';
const { REACT_APP_API_URL: API_URL } = process.env;

const getUserByEmail = async email => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/users/${email}`, {
		method: 'GET',
		headers: { authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
	});

	const data = await res.json();

	if (data.error) throw new Error(data.error);

	return data;
};

export const useGetUserByEmail = (email, options = {}) => {
	return useQuery(['user', email], () => getUserByEmail(email), {
		staleTime: 5 * 60 * 1000,
		retry: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		...options,
	});
};

const addUserFurnizor = async (email, { userId, furnizori, crtFurnizor }) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/users/${email}/furnizori`, {
		method: 'POST',
		headers: { authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
		body: JSON.stringify({ userId, furnizori, crtFurnizor }),
	});

	const data = await res.json();

	if (data.error) throw new Error(data.error);

	return data;
};
export const useAddUserFurnizor = () => {
	return useMutation(({ email, mutationData }) => addUserFurnizor(email, mutationData));
};

const updateGestiuniFurnizor = async (userId, furnizor) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/wms/gestiuni`, {
		method: 'PUT',
		headers: { authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
		body: JSON.stringify({ userId, furnizor }),
	});

	const data = await res.json();

	if (data.error) throw new Error(data.error);

	return data;
};
export const useUpdateGestiuniFurnizor = () => {
	return useMutation(({ userId, furnizor }) => updateGestiuniFurnizor(userId, furnizor));
};

const deleteFurnizor = async (userId, furnizorId) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/wms/furnizori/delete`, {
		method: 'POST',
		headers: { authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
		body: JSON.stringify({ userId, furnizorId }),
	});

	const data = await res.json();

	if (data.error) throw new Error(data.error);

	return data;
};

export const useDeleteFurnizor = () => {
	return useMutation(({ userId, furnizorId }) => deleteFurnizor(userId, furnizorId));
};

const updateSerieAvizIesire = async (userId, idSerieAvizIesire) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/wms/serie-aviz-iesire`, {
		method: 'PUT',
		headers: { authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
		body: JSON.stringify({ userId, idSerieAvizIesire }),
	});

	const data = await res.json();

	if (data.error) throw new Error(data.error);

	return data;
};
export const useUpdateSerieAvizIesire = () => {
	return useMutation(({ userId, idSerieAvizIesire }) => updateSerieAvizIesire(userId, idSerieAvizIesire));
};

const updateDepoziteCustodie = async (userId, depoziteCustodie) => {
	const ref = doc(firestore, 'users', userId);
	const document = await getDoc(ref);
	if (!document.exists) {
		throw new Error(`Userul cu ID ${userId} nu exista!`);
	}

	await updateDoc(ref, { depoziteCustodie });

	return 'ok';
};
export const useUpdateDepoziteCustodie = () => {
	return useMutation(({ userId, depoziteCustodie }) => updateDepoziteCustodie(userId, depoziteCustodie));
};

const updateUserNotifications = async (userId, toUpdate) => {
	const ref = doc(firestore, 'users', userId);
	const document = await getDoc(ref);
	if (!document.exists) {
		throw new Error(`Userul cu ID ${userId} nu exista!`);
	}

	const user = document.data();
	const newNotifications = { ...user.notifications, ...toUpdate };

	await updateDoc(ref, { notifications: newNotifications });

	return 'ok';
};
export const useUpdateUserNotifications = () => {
	return useMutation(({ userId, toUpdate }) => updateUserNotifications(userId, toUpdate));
};
