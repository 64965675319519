import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';
import { Box, Button, Text, useToast } from '@chakra-ui/react';
import { add, format, set } from 'date-fns';
import { useDownloadLabStats } from '../../state/server-side/queries/laborator';
import { saveAs } from 'file-saver';

const DatePicker = ({ selectedDate, onChange, isClearable = false, showPopperArrow = false, ...props }) => {
	return (
		// if you don't want to use chakra's colors or you just wwant to use the original ones,
		// set className to "light-theme-original" ↓↓↓↓
		<div className={'dark-theme'}>
			<ReactDatePicker
				selected={selectedDate}
				onChange={onChange}
				isClearable={isClearable}
				showPopperArrow={showPopperArrow}
				dateFormat={'dd.MM.yyyy'}
				className="react-datapicker__input-text" //input is white by default and there is no already defined class for it so I created a new one
				calendarStartDay={1}
				{...props}
			/>
		</div>
	);
};

const LaboratorDatepicker = () => {
	const toast = useToast();
	const today = set(new Date(), { hours: 0, minutes: 0, milliseconds: 0 });
	const [startDate, setStartDate] = useState(add(today, { months: -1 }));
	const [endDate, setEndDate] = useState(today);

	const download = useDownloadLabStats();

	const handleSubmit = () => {
		download.mutate(
			{ startDate: format(startDate, 'yyyyMMdd'), endDate: format(endDate, 'yyyyMMdd') },
			{
				onSuccess: data => {
					saveAs(data, 'test.xlsx');
				},
				onError: error => {
					toast({
						title: 'Eroare!',
						description: error.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				},
			}
		);
	};

	return (
		<Box>
			<Text>Incepand cu:</Text>
			<DatePicker
				selected={startDate}
				onChange={date => setStartDate(date)}
				selectsStart
				startDate={startDate}
				endDate={endDate}
			/>
			<Text mt={4}>Pana la:</Text>
			<DatePicker
				selected={endDate}
				onChange={date => setEndDate(date)}
				selectsEnd
				startDate={startDate}
				endDate={endDate}
				minDate={startDate}
			/>
			<Button mt={4} onClick={handleSubmit}>
				Download Excel
			</Button>
		</Box>
	);
};

export default LaboratorDatepicker;
