import { getAuth, onAuthStateChanged as onFirebaseAuth, signOut, signInWithEmailAndPassword } from '@firebase/auth';
import { initializeApp } from 'firebase/app';
import { useState, useEffect, useContext, createContext } from 'react';
import { Navigate } from 'react-router-dom';
import { config } from '../firebase-config';
import { getFirestore } from '@firebase/firestore';

export const firebaseApp = initializeApp(config);
export const firestore = getFirestore(firebaseApp);

export const AuthContext = createContext();

export const AuthRoute = ({ page }) => {
	const { isAuthenticated } = useAuthState();
	return isAuthenticated ? page : <Navigate to="/login" />;
};

export const UnauthRoute = ({ page }) => {
	const { isAuthenticated } = useAuthState();
	return !isAuthenticated ? page : <Navigate to="/" />;
};

export const AuthContextProvider = props => {
	const [user, setUser] = useState();
	const [error, setError] = useState();
	const [initialising, setInitialising] = useState(true);

	// console.log('user', user);

	const login = async (email, password) => {
		try {
			await signInWithEmailAndPassword(getAuth(), email, password);
		} catch (error) {
			setError(error);
		}
	};

	const logout = async () => {
		try {
			await signOut(getAuth());
		} catch (error) {
			console.error(error);
		}
	};

	const onAuthStateChanged = async authUser => {
		if (authUser) {
			try {
				const userDetails = authUser.reloadUserInfo;
				const claims = JSON.parse(userDetails.customAttributes);

				const userWithClaims = {
					id: userDetails.localId,
					email: userDetails.email,
					...claims,
				};

				// console.log('userWithClaims', userWithClaims);

				setUser(userWithClaims);
			} catch (error) {
				console.error(error);
				setError(error);
			} finally {
				setInitialising(false);
			}
		} else {
			setUser(authUser);
			setInitialising(false);
		}
	};

	useEffect(() => {
		const unsubscribe = onFirebaseAuth(getAuth(), onAuthStateChanged, setError);
		return () => unsubscribe();
	}, []);

	return <AuthContext.Provider value={{ user, error, initialising, login, logout }} {...props} />;
};

export const useAuthState = () => {
	const auth = useContext(AuthContext);
	return { ...auth, isAuthenticated: auth.user != null };
};
