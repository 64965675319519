import { Heading, IconButton, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserByEmail } from '../../../state/server-side/mutations/users';
import LoadingPage from '../../loading';
import { BsArrowLeft } from 'react-icons/bs';
import { ListaFurnizori } from '../../../components/admin/users/lista-furnizori';
import { DocumentIesiri } from '../../../components/admin/users/document-iesiri';
import { DepoziteCustodie } from '../../../components/admin/users/depozite-custodie';
import { UserNotifications } from '../../../components/admin/users/notifications';

export const EditUserPage = () => {
	const { email } = useParams();
	const navigate = useNavigate();
	const { data: userData, isLoading: isUserLoading, error: userError } = useGetUserByEmail(email);

	const handleCloseEdit = () => {
		navigate(-1);
	};

	if (isUserLoading) {
		return <LoadingPage />;
	}

	if (userError) {
		return (
			<Text>
				Eroare:{' '}
				{userError.message.includes('There is no user record corresponding to the provided')
					? 'Nu am gasit userul cautat!'
					: userError.message}
			</Text>
		);
	}

	return (
		<>
			<Heading as={'h2'} size={'lg'} mb={8}>
				<Tooltip label="Inapoi">
					<IconButton variant={'ghost'} icon={<BsArrowLeft />} mr={2} onClick={handleCloseEdit} />
				</Tooltip>
				{email}
			</Heading>
			<Text>ID: {userData.id}</Text>
			<Text>E-mail: {userData.email}</Text>
			<Text>Departament: {userData.department}</Text>
			<Text>Rol: {userData.role}</Text>
			<Tabs variant={'enclosed-colored'} mt={4}>
				<TabList>
					<Tab>Top document pentru avize custodie</Tab>
					<Tab>Depozite permise pentru avize custodie</Tab>
					<Tab>Permisiuni furnizori pentru receptie marfa</Tab>
					<Tab>Notificari</Tab>
				</TabList>

				<TabPanels>
					<TabPanel>
						<DocumentIesiri user={userData} />
					</TabPanel>
					<TabPanel>
						<DepoziteCustodie user={userData} />
					</TabPanel>
					<TabPanel>
						<ListaFurnizori user={userData} />
					</TabPanel>
					<TabPanel>
						<UserNotifications user={userData} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</>
	);
};
