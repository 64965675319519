import { useFetchPhotos } from '../state/server-side/queries/photos';
import { useFetchOrder } from '../state/server-side/queries/order';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Grid } from '@chakra-ui/react';
import LoadingPage from './loading';
import BackButton from '../components/common/basic/back-button';
import DownloadButton from '../components/photos/download-button';
import OrderDetails from '../components/common/order-details';
import ImageBox from '../components/photos/image-box';
import RefreshPhotosButton from '../components/photos/refresh-button';

const GalleryPage = () => {
	const { orderId, type } = useParams();
	const navigate = useNavigate();

	const {
		data: photos,
		isError: isPhotosError,
		isLoading: isPhotosLoading,
		error: photosError,
		refetch: refetchPhotos,
	} = useFetchPhotos(type, orderId);

	const { data: order, isLoading: isOrderLoading, isError: isOrderError, error: orderError } = useFetchOrder(orderId);

	const handleBack = () => {
		navigate(`/${orderId}/photos`);
	};

	if (isPhotosLoading || isOrderLoading) {
		return <LoadingPage />;
	}

	if (isPhotosError || isOrderError) {
		console.log('error:', { photosError, orderError });
		return <div>error</div>;
	}

	return (
		<>
			<Box d="flex">
				<Box mt={6} px={2} d={'flex'} w="80%" alignItems="center" justifyContent="flex-start">
					<BackButton onClick={handleBack}>Inapoi</BackButton>
					<DownloadButton type={type} orderId={orderId} />
					<RefreshPhotosButton action={refetchPhotos} loading={isPhotosLoading} />
				</Box>
				<OrderDetails data={order} />
			</Box>
			<Grid gap={6} justifyItems="center" my={6}>
				<Grid templateColumns="repeat(auto-fill, minmax(400px, 1fr))" gap={2} w="100%">
					{photos.map(photo => (
						<ImageBox key={photo.id} src={photo.link} />
					))}
				</Grid>
				<BackButton onClick={handleBack}>Inapoi</BackButton>
			</Grid>
		</>
	);
};

export default GalleryPage;
