import { Button, Flex, Heading, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatDate, getDateDifference } from '../../../utils/helpers';
import { useCreateRevenire, useUploadFile } from '../../../state/server-side/mutations/documents-status';
import { useQueryClient } from 'react-query';
import { IoMdCheckmarkCircle, IoMdCloseCircle } from 'react-icons/io';
import NoApplyButton from '../../../pages/file-status/no-apply-button';

const CurrentStatus = ({ id, name, startDate, deadline }) => {
	const { orderId } = useParams();
	const [isExpired, setIsExpired] = useState(false);
	const [notifyClient, setNotifyClient] = useState('true');
	const toast = useToast();
	const hiddenInput = useRef(null);
	const uploadFile = useUploadFile();
	const createRevenire = useCreateRevenire();
	const queryClient = useQueryClient();

	const isRevenireAllowed = name === 'RASPUNS RECONSTATARE' || name === 'ACCEPT PLATA RECEPTIONAT';

	const handleRevenire = () => {
		createRevenire.mutate(
			{ statusId: id, mutationData: { orderId } },
			{
				onSuccess: () => {
					queryClient.invalidateQueries(['statuses', orderId]);
				},
			}
		);
	};

	const handleInformareClient = () => {
		setNotifyClient(prev => (prev === 'true' ? 'false' : 'true'));
	};

	const handleImageUpload = async e => {
		const files = e.target.files;

		// console.log({ files: files[0] });

		const formData = new FormData();
		formData.append('file', files[0], `${orderId}_${name}_${Date.now()}.${files[0].type.split('/')[1]}`);

		uploadFile.mutate(
			{ orderId, statusId: id, formData, notifyClient },
			{
				onSuccess: () => {
					queryClient.invalidateQueries(['statuses', orderId]);
				},
				onError: error => {
					toast({
						title: 'Refresh pagina!',
						description: error.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				},
			}
		);
	};

	useEffect(() => {
		if (getDateDifference(new Date(deadline)).expired) {
			setIsExpired(true);
		}
		// eslint-disable-next-line
	}, []);

	return (
		<Flex
			borderWidth={'thin'}
			borderRadius={'md'}
			bgColor={deadline && isExpired ? 'red.700' : 'green.700'}
			padding={4}
			mb={3}
			justifyContent={'space-between'}
			alignItems={'center'}
		>
			<Heading as={'h4'} size="md">
				{name}
			</Heading>

			{name === 'DE INCARCAT PIESE DE SCHIMB IN CDMS' ? <NoApplyButton orderId={orderId} statusId={id} /> : null}

			{isExpired && isRevenireAllowed ? <Button onClick={handleRevenire}>Revenire</Button> : null}

			<Text>Inceput la: {formatDate(new Date(startDate))} </Text>

			{/* IF exista deadline si e expirat */}
			{deadline && isExpired ? (
				<Text fontWeight={'bold'}>
					EXPIRAT de {getDateDifference('now', new Date(deadline)).distance} ({formatDate(new Date(deadline))})
				</Text>
			) : // ELSE IF doar exista deadline
			deadline ? (
				<Text>
					Expira in {getDateDifference(new Date(deadline)).distance} ({formatDate(new Date(deadline))})
				</Text>
			) : // ELSE IF daca nu are deadline si statusul e de programat
			!deadline && name === 'DE PROGRAMAT' ? (
				<Text>Exista alte comenzi deschise pentru acest auto</Text>
			) : (
				// ELSE daca nu are deadline
				<Text>Fara termen de expirare</Text>
			)}

			{['RASPUNS RECONSTATARE', 'SOLICITARE RECONSTATARE'].includes(name) ? (
				<Button
					colorScheme={notifyClient === 'true' ? 'green' : 'red'}
					gap={'1'}
					size={'xs'}
					onClick={handleInformareClient}
				>
					{notifyClient === 'true' ? <IoMdCheckmarkCircle /> : <IoMdCloseCircle />}
					<Text fontSize={'xs'} textTransform={'uppercase'}>
						Informare client
					</Text>
				</Button>
			) : null}

			{name === 'ASTEAPTA PIESE' || name === 'DE PROGRAMAT' ? null : (
				<>
					<Button isLoading={uploadFile.isLoading} onClick={() => hiddenInput.current.click()}>
						Incarca document
					</Button>
					<input hidden type="file" ref={hiddenInput} onChange={e => handleImageUpload(e)} />
				</>
			)}
		</Flex>
	);
};

export default CurrentStatus;
