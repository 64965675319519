import { Flex, Heading, Link, Text } from '@chakra-ui/react';
import { saveAs } from 'file-saver';
import React from 'react';
import { useParams } from 'react-router-dom';
import { formatDate } from '../../../utils/helpers';
import { getAuth } from '@firebase/auth';
const { REACT_APP_API_URL: API_URL } = process.env;

const PrevStatus = ({ name, filename, endUser, endDate }) => {
	const { orderId } = useParams();

	const handleDownload = async () => {
		try {
			const token = await getAuth().currentUser.getIdToken();
			const res = await fetch(`${API_URL}/file-status/${orderId}/download?filename=${filename}`, {
				headers: { authorization: `Bearer ${token}` },
			});
			const data = await res.blob();
			saveAs(data, filename);
		} catch (error) {
			console.log({ error });
		}
	};

	return (
		<Flex
			borderWidth={'thin'}
			borderRadius={'md'}
			bgColor={'whiteAlpha.50'}
			padding={4}
			mb={3}
			justifyContent={'space-between'}
			alignItems={'center'}
		>
			<Heading as={'h4'} size="md">
				{name}
			</Heading>
			{filename === 'no-file' ? null : (
				<Link color={'blue.200'} onClick={handleDownload}>
					Descarca document
				</Link>
			)}
			<Text>
				Incarcat de <strong>{endUser}</strong> la <strong>{formatDate(new Date(endDate))}</strong>
			</Text>
		</Flex>
	);
};

export default PrevStatus;
