import {
	Alert,
	AlertIcon,
	Button,
	FormControl,
	FormErrorMessage,
	Input,
	Select,
	Text,
	useToast,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useCreatePartMutation } from '../../state/server-side/mutations/addons';
import { useFetchParts } from '../../state/server-side/queries/addons';
import { useAuthState } from '../../utils/firebase';

const initialErrors = { name: '', parent: '', hasDamage: '', hasChildren: '', main: '' };
const initialValues = { name: '', parent: '', hasDamage: '', hasChildren: '' };

const PartsNomenclatureForm = () => {
	const { user } = useAuthState();
	const { data } = useFetchParts();
	const [errors, seterrors] = useState(initialErrors);
	const [values, setvalues] = useState(initialValues);
	const [selectedParent, setselectedParent] = useState(false);
	const [filteredParts, setfilteredParts] = useState([]);
	const toast = useToast();
	const mutation = useCreatePartMutation();

	const handleSelectParent = e => {
		setselectedParent(true);
		setfilteredParts([]);
		setvalues(prev => ({ ...prev, parent: e.target.textContent }));
	};

	const filterParts = () => {
		if (selectedParent) return;

		const parent = values.parent.toUpperCase();

		if (parent.length < 2) {
			return;
		}

		const filtered = data.parts.filter(part => part.name.includes(parent));
		setfilteredParts(filtered);
	};

	const onInputChange = e => {
		const name = e.target.name;
		const value = e.target.value;

		if (name === 'parent' && selectedParent) {
			setselectedParent(false);
		}

		if (value.length === 0) {
			setvalues(prev => ({ ...prev, [name]: value }));
			seterrors(prev => ({ ...prev, [name]: 'Obligatoriu!' }));
			return;
		}

		seterrors(prev => ({ ...prev, [name]: '' }));
		setvalues(prev => ({ ...prev, [name]: value }));
	};

	const resetForm = () => {
		seterrors(initialErrors);
		setvalues(initialValues);
	};

	function onSubmit(e) {
		e.preventDefault();

		const isError = errors.name || errors.parent || errors.hasDamage || errors.hasChildren;
		const isEmpty =
			values.name.length === 0 ||
			values.parent.length === 0 ||
			values.hasDamage.length === 0 ||
			values.hasChildren.length === 0;

		if (isError || isEmpty) {
			seterrors(prev => ({
				...prev,
				main: 'Nu s-a putut trimite. Completeaza toate campurile si incearca din nou!',
			}));
			return;
		}

		const parentExist = data.parts.find(part => part.name === values.parent);
		if (!parentExist) {
			seterrors(prev => ({
				...prev,
				main: 'Parintele completat nu exista in nomenclator!',
			}));
			return;
		}

		if (!parentExist.hasChildren) {
			seterrors(prev => ({
				...prev,
				main: 'Piesa selectată ca părinte nu poate avea subelemente!',
			}));
			return;
		}

		const partToAdd = {
			name: values.name.toUpperCase().trim(),
			parent: parentExist.id,
			hasDamage: parseInt(values.hasDamage),
			hasChildren: parseInt(values.hasChildren),
			user: user.email,
		};

		mutation.mutate(
			{ ...partToAdd },
			{
				onSuccess: () => {
					resetForm();
					toast({
						description: 'Piesa a fost adaugata!',
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
				},
				onError: error => {
					toast({
						title: 'Eroare!',
						description: error.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				},
			}
		);
	}

	useEffect(() => {
		filterParts();
		// eslint-disable-next-line
	}, [values.parent]);

	return (
		<form onSubmit={onSubmit} style={{ width: '100%', display: 'grid', gap: '20px' }}>
			<FormControl isInvalid={errors.name}>
				<Input
					id="name"
					name="name"
					autoComplete="off"
					value={values.name}
					placeholder="Denumire"
					onChange={onInputChange}
				/>
				<FormErrorMessage>{errors.name && errors.name}</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={errors.parent}>
				<Input
					id="parent"
					name="parent"
					autoComplete="off"
					value={values.parent}
					placeholder="Parinte"
					onChange={onInputChange}
				/>
				<FormErrorMessage>{errors.parent && errors.parent}</FormErrorMessage>
			</FormControl>
			{filteredParts.length !== 0 &&
				filteredParts.map(part => (
					<Text
						name={part.id}
						cursor={'pointer'}
						w="100%"
						bg="gray.700"
						key={part.id}
						pl={4}
						py={2}
						borderRadius="md"
						onClick={handleSelectParent}
					>
						{part.name}
					</Text>
				))}

			<FormControl isInvalid={errors.hasDamage}>
				<Select
					id="hasDamage"
					name="hasDamage"
					value={values.hasDamage}
					placeholder="Este grupă majoră?"
					onChange={onInputChange}
				>
					<option value={0}>Da</option>
					<option value={1}>Nu</option>
				</Select>
				<FormErrorMessage>{errors.hasDamage && errors.hasDamage}</FormErrorMessage>
			</FormControl>

			<FormControl isInvalid={errors.hasChildren}>
				<Select
					id="hasChildren"
					name="hasChildren"
					value={values.hasChildren}
					placeholder="Are subelemente?"
					onChange={onInputChange}
				>
					<option value={1}>Da</option>
					<option value={0}>Nu</option>
				</Select>
				<FormErrorMessage>{errors.hasChildren && errors.hasChildren}</FormErrorMessage>
			</FormControl>

			{errors.main && (
				<Alert status={'error'}>
					<AlertIcon />
					{errors.main}
				</Alert>
			)}

			<Button size={'lg'} mt={4} colorScheme="blue" type="submit" isLoading={mutation.isLoading}>
				Adauga
			</Button>
		</form>
	);
};

export default PartsNomenclatureForm;
