import { IconButton, Tooltip } from '@chakra-ui/react';
import { MdRefresh } from 'react-icons/md';

const RefreshPhotosButton = ({ action, loading }) => {
  const handleClick = () => {
    action();
  };

  return (
    <Tooltip label="Actualizeaza lista poze" hasArrow>
      <IconButton
        variant="ghost"
        size="lg"
        w="70px"
        h="70px"
        icon={<MdRefresh size="30" />}
        colorScheme="blue"
        isRound
        isLoading={loading}
        onClick={handleClick}
      />
    </Tooltip>
  );
};

export default RefreshPhotosButton;
