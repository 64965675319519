import { Alert } from '@chakra-ui/react';
import { SimpleImg } from 'react-simple-img';

const ImageBox = ({ src }) => {
  return (
    <Alert p={6} justifyContent="center" minH="300px">
      <SimpleImg placeholder={false} src={src} />
    </Alert>
  );
};

export default ImageBox;
