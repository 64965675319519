import { IconButton, Tooltip } from '@chakra-ui/react';
import { MdCloudDownload } from 'react-icons/md';
import { saveAs } from 'file-saver';
import { useDownloadDocuments } from '../../state/server-side/mutations/documents-status';

const DownloadFilesButton = ({ orderId }) => {
	const mutation = useDownloadDocuments();

	const handleClick = () => {
		mutation.mutate(
			{ orderId },
			{
				onSuccess: data => {
					saveAs(data, `documente_${orderId}.zip`);
				},
			}
		);
	};

	return (
		<Tooltip label="Descarca toate documentele" hasArrow>
			<IconButton
				variant="ghost"
				size="lg"
				w="70px"
				h="70px"
				icon={<MdCloudDownload size="30" />}
				colorScheme="blue"
				isRound
				isLoading={mutation.isLoading}
				onClick={handleClick}
			/>
		</Tooltip>
	);
};

export default DownloadFilesButton;
