import React from 'react';
import { Link } from 'react-router-dom';
import { Heading, LinkBox, LinkOverlay, SimpleGrid } from '@chakra-ui/react';
import { useFetchInsurers } from '../../state/server-side/queries/documents-status';

const ConfigureDocumentsStatus = () => {
	const { data, isLoading } = useFetchInsurers();

	if (isLoading) return <div>Loading...</div>;

	return (
		<>
			<Heading as={'h2'} mb={6}>
				Asiguratori
			</Heading>
			<SimpleGrid minChildWidth="250px" spacing="40px">
				{data.map(insurer => (
					<LinkBox
						as="article"
						key={insurer.id}
						borderWidth={'thin'}
						borderRadius={'md'}
						bgColor={'whiteAlpha.50'}
						padding={4}
						mb={3}
						justifyContent={'space-between'}
						alignItems={'center'}
					>
						<LinkOverlay as={Link} to={`${insurer.id}`}>
							{insurer.name}
						</LinkOverlay>
					</LinkBox>
				))}
			</SimpleGrid>
		</>
	);
};

export default ConfigureDocumentsStatus;
