import { Center, Flex, Heading } from '@chakra-ui/react';
import LoginForm from '../components/login/form';

const LoginPage = () => {
  return (
    <Center h={'100vh'}>
      <Flex direction={'column'} minW={'400px'} alignItems={'center'}>
        <Heading mb={'50px'}>Carolive</Heading>
        <LoginForm />
      </Flex>
    </Center>
  );
};

export default LoginPage;
