import { Button } from '@chakra-ui/react';

const MainButton = ({ children, ...props }) => {
  return (
    <Button
      h={'60px'}
      colorScheme={'blue'}
      fontWeight={'bold'}
      fontSize={'3xl'}
      {...props}
    >
      {children}
    </Button>
  );
};

export default MainButton;
