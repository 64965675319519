import { useQuery } from 'react-query';
import { getAuth } from '@firebase/auth';

const { REACT_APP_API_URL: API_URL } = process.env;

const fetchAddons = async orderId => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/addons/${orderId}`, {
		headers: { authorization: `Bearer ${token}` },
	});
	const data = await res.json();

	if (data.error) throw new Error(`[fetchAddons] ${data.error}`);

	return data;
};

export const useFetchAddons = (orderId, options = {}) => {
	return useQuery(['addons', orderId], () => fetchAddons(orderId), {
		staleTime: 5 * 60 * 1000,
		retry: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		...options,
	});
};

const fetchParts = async orderId => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/parts/${orderId}`, {
		headers: { authorization: `Bearer ${token}` },
	});
	const data = await res.json();

	if (data.error) throw new Error(`[fetchAddons] ${data.error}`);

	return data;
};

export const useFetchParts = (orderId, options = {}) => {
	return useQuery(['parts'], () => fetchParts(orderId), {
		staleTime: 5 * 60 * 1000,
		retry: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		...options,
	});
};
