import { Button, useToast } from '@chakra-ui/react';
import { useAddManoperaTesla } from '../../../state/server-side/mutations/tesla';
import { useRef } from 'react';

const ManoperaTeslaButton = ({ orderId, type, ...props }) => {
	const addManoperaTesla = useAddManoperaTesla();
	const toast = useToast();
	const hiddenInput = useRef(null);

	const handleUpload = async e => {
		const files = e.target.files;

		const formData = new FormData();
		formData.append('file', files[0]);

		addManoperaTesla.mutate(
			{ orderId, type, formData },
			{
				onSuccess: () => {
					toast({
						description: `Linii ${type} adaugate cu succes in manopera!`,
						status: 'success',
						duration: 5000,
						isClosable: true,
					});
				},
				onError: error => {
					toast({
						title: 'Eroare!',
						description: error.message,
						status: 'error',
						duration: 10000,
						isClosable: true,
					});
				},
			}
		);
	};

	return (
		<>
			<Button
				h={'60px'}
				colorScheme={'blue'}
				fontWeight={'bold'}
				fontSize={'3xl'}
				{...props}
				onClick={() => hiddenInput.current.click()}
			>
				{type === 'spalatorie' ? 'Spalatorie TESLA' : 'PDI TESLA'}
			</Button>
			<input hidden type="file" ref={hiddenInput} key={Date.now()} onChange={handleUpload} />
		</>
	);
};

export default ManoperaTeslaButton;
