import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NewEdit from '../../components/admin/new-edit';
import AdminPage from '../../pages/admin';
import ConfigureDocumentsStatus from '../../pages/admin/documents-status';
import ConfigureInsurerStatuses from '../../pages/admin/insurer';
import PartsNomenclature from '../../pages/admin/parts-nomenclature';
import { AuthRoute } from '../../utils/firebase';
import StatusLaboratorPage from '../../pages/admin/status-laborator';
import { UsersPage } from '../../pages/admin/users';
import { EditUserPage } from '../../pages/admin/users/edit-user';

const AdminRoutes = () => {
	return (
		<Routes>
			<Route path="/" element={<AuthRoute page={<AdminPage />} />}>
				<Route path="parts-nomenclature" element={<AuthRoute page={<PartsNomenclature />} />} />
				<Route path="documents-status/:insurerId/edit/:statusId" element={<AuthRoute page={<NewEdit />} />} />
				<Route path="documents-status/:insurerId" element={<AuthRoute page={<ConfigureInsurerStatuses />} />} />
				<Route path="documents-status" element={<AuthRoute page={<ConfigureDocumentsStatus />} />} />
				<Route path="laborator-status" element={<AuthRoute page={<StatusLaboratorPage />} />} />
				<Route path="users/:email" element={<AuthRoute page={<EditUserPage />} />} />
				<Route path="users" element={<AuthRoute page={<UsersPage />} />} />
			</Route>
		</Routes>
	);
};

export default AdminRoutes;
