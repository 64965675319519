const {
	REACT_APP_FB_KEY,
	REACT_APP_FB_DOMAIN,
	REACT_APP_FB_PROJECT,
	REACT_APP_FB_BUCKET,
	REACT_APP_FB_MESSAGING,
	REACT_APP_FB_APPID,
	REACT_APP_FB_MEASUREMENT,
} = process.env;

export const config = {
	apiKey: REACT_APP_FB_KEY,
	authDomain: REACT_APP_FB_DOMAIN,
	projectId: REACT_APP_FB_PROJECT,
	storageBucket: REACT_APP_FB_BUCKET,
	messagingSenderId: REACT_APP_FB_MESSAGING,
	appId: REACT_APP_FB_APPID,
	measurementId: REACT_APP_FB_MEASUREMENT,
};
