import { useQuery, useMutation } from 'react-query';
import { getAuth } from '@firebase/auth';

const { REACT_APP_API_URL: API_URL } = process.env;

const queryLaborator = async (sortBy = 'created_at', sortDirection = 'asc', pageNumber = 1, filter) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(
		`${API_URL}/laborator/query?sortBy=${sortBy}&sortDirection=${sortDirection}&pageNumber=${pageNumber}&filter=${filter}`,
		{
			headers: { authorization: `Bearer ${token}` },
		}
	);
	const data = await res.json();

	if (data.error) throw new Error(`[queryLaborator] ${data.error}`);

	return data;
};

export const useQueryLaborator = (sortBy, sortDirection, pageNumber, filter, options = {}) => {
	return useQuery(
		['lab-query', sortBy, sortDirection, pageNumber, filter],
		() => queryLaborator(sortBy, sortDirection, pageNumber, filter),
		{
			staleTime: 1 * 60 * 1000,
			retry: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
			...options,
		}
	);
};

const downloadLabStats = async (startDate, endDate) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/laborator/query?startDate=${startDate}&endDate=${endDate}`, {
		headers: { authorization: `Bearer ${token}` },
	});

	if (!res.ok) {
		const error = await res.json();
		throw new Error(error.error);
	}

	return await res.blob();
};

export const useDownloadLabStats = () => {
	return useMutation(({ startDate, endDate }) => downloadLabStats(startDate, endDate));
};
