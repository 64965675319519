import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useAuthState } from '../../utils/firebase';

export default function LoginForm() {
  const { login, error } = useAuthState();

  const errorMessage = error?.message.includes('Firebase')
    ? error?.message.replace('Firebase: ', '')
    : error?.message;

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  function onSubmit(values) {
    login(values.email, values.password);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <FormControl isInvalid={errors.email}>
        <FormLabel htmlFor="email">E-mail</FormLabel>
        <Input
          id="email"
          type="email"
          {...register('email', {
            required: 'N-ai completat e-mailul!',
            pattern: {
              value:
                // eslint-disable-next-line
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message:
                'E-mailul trebuie sa fie sub forma: prenume.nume@dabauto.ro',
            },
          })}
        />
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.password} mt={2}>
        <FormLabel htmlFor="password">Parola</FormLabel>
        <Input
          id="password"
          type="password"
          {...register('password', {
            required: 'N-ai completat parola!',
          })}
        />
        <FormErrorMessage>
          {errors.password && errors.password.message}
        </FormErrorMessage>
      </FormControl>

      {error && (
        <Alert status="error" mt={8}>
          <AlertIcon />
          {errorMessage}
        </Alert>
      )}

      <Button
        mt={8}
        size={'lg'}
        colorScheme={'blue'}
        isLoading={isSubmitting}
        type="submit"
        isFullWidth
      >
        Login
      </Button>
    </form>
  );
}
