import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
	FormErrorMessage,
	FormControl,
	Input,
	Button,
	Flex,
	InputGroup,
	InputRightElement,
	Center,
} from '@chakra-ui/react';
import AdminButton from '../components/home/admin-button';
import { useAuthState } from '../utils/firebase';

export default function SearchForm() {
	const navigate = useNavigate();
	const { user } = useAuthState();

	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
	} = useForm();

	function onSubmit(values) {
		navigate(`/${values.orderId}`);
	}

	return (
		<Center h={'calc(100vh - 64px)'}>
			<Flex direction={'column'} minW={'400px'}>
				<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
					<FormControl isInvalid={errors.orderId}>
						<InputGroup>
							<Input
								id="orderId"
								type="number"
								size={'lg'}
								placeholder="Crt deviz"
								{...register('orderId', {
									required: 'Trebuie completat!',
								})}
							/>
							<InputRightElement
								width="90px"
								mt={1}
								children={
									<Button size={'lg'} isLoading={isSubmitting} type="submit">
										Cauta
									</Button>
								}
							/>
						</InputGroup>
						<FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
					</FormControl>
				</form>
				{user.level > 2 && <AdminButton />}
			</Flex>
		</Center>
	);
}
