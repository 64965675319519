import { IconButton, Tooltip } from '@chakra-ui/react';
import { IoMdClose } from 'react-icons/io';
import { useQueryClient } from 'react-query';
import { useAddSkipFlag } from '../../state/server-side/mutations/documents-status';

const NoApplyButton = ({ orderId, statusId }) => {
	const mutation = useAddSkipFlag();
	const queryClient = useQueryClient();

	const handleClick = () => {
		mutation.mutate(
			{ orderId, statusId },
			{
				onSuccess: () => {
					queryClient.invalidateQueries(['statuses', orderId]);
				},
			}
		);
	};

	return (
		<Tooltip label="Nu se aplica" hasArrow>
			<IconButton
				variant="solid"
				size="md"
				icon={<IoMdClose size="25" />}
				isRound
				isLoading={mutation.isLoading}
				onClick={handleClick}
			/>
		</Tooltip>
	);
};

export default NoApplyButton;
