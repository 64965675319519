import { Checkbox, Heading, useToast } from '@chakra-ui/react';
import { useUpdateUserNotifications } from '../../../state/server-side/mutations/users';
import { useState } from 'react';

const defaultNotifications = { solicitariPieseCaroserie: false };

export const UserNotifications = ({ user }) => {
	const toast = useToast();
	const { id, notifications } = user;

	const _notifications = notifications ?? defaultNotifications;

	const [value, setValue] = useState(_notifications);

	const mutation = useUpdateUserNotifications();

	function handleChange(e, name) {
		const checked = e.target.checked;

		mutation.mutate(
			{ userId: id, toUpdate: { [name]: checked } },
			{
				onSuccess: data => {
					toast({
						description: `Success!`,
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
					setValue(prev => ({ ...prev, [name]: checked }));
				},
				onError: error => {
					toast({
						title: 'Eroare!',
						description: error.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				},
			}
		);
	}

	return (
		<>
			<Heading as={'h3'} size={'md'} mb={3}>
				Afiseaza notificari in WMS cand:
			</Heading>
			<Checkbox
				size="lg"
				spacing="1rem"
				isChecked={value.solicitariPieseCaroserie}
				isDisabled={mutation.isLoading}
				isIndeterminate={mutation.isLoading}
				onChange={e => handleChange(e, 'solicitariPieseCaroserie')}
			>
				Apar solicitari de piese din tinichigerie
			</Checkbox>
		</>
	);
};
