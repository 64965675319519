import { useQuery } from 'react-query';
import { getAuth } from '@firebase/auth';

const { REACT_APP_API_URL: API_URL } = process.env;

const fetchOrder = async orderId => {
  const token = await getAuth().currentUser.getIdToken();

  const res = await fetch(`${API_URL}/order/${orderId}`, {
    headers: { authorization: `Bearer ${token}` },
  });
  const data = await res.json();

  if (data.error) throw new Error(`[fetchOrder] ${data.error}`);

  return data;
};

export const useFetchOrder = (orderId, options = {}) => {
  return useQuery(['order', orderId], () => fetchOrder(orderId), {
    staleTime: 5 * 60 * 1000,
    retry: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
