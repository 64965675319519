import { Center, Grid } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useFetchOrder } from '../../state/server-side/queries/order';
import LoadingPage from '../../pages/loading';
import OrderDetails from './order-details';

const MainLayout = ({ children, ...props }) => {
  const { orderId } = useParams();
  const { data, isLoading, isError } = useFetchOrder(orderId);

  if (isLoading) {
    return <LoadingPage offset={64} />;
  }

  if (isError) {
    return <div>error</div>;
  }

  return (
    <Grid templateRows={'auto calc(100vh - 232px)'}>
      <OrderDetails data={data} />
      <Center h={'100%'} {...props}>
        {children}
      </Center>
    </Grid>
  );
};

export default MainLayout;
