import { Center, Spinner } from '@chakra-ui/react';

const LoadingPage = ({ offset }) => {
  return (
    <Center h={offset ? `calc(100vh - ${offset}px)` : '100vh'}>
      <Spinner size={'lg'} />
    </Center>
  );
};

export default LoadingPage;
