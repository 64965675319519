import './editor.scss';

import React from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Mention from '@tiptap/extension-mention';
import { Box, Button, Heading, useToast, VStack } from '@chakra-ui/react';
import { MenuBar } from './menu-bar';
import { suggestion } from './mention-suggestion';
import { clientSuggestion } from './client-suggestions';

const RichTextEditor = ({ type, content, updateTemplate }) => {
	const { statusId, insurerId } = useParams();
	const navigate = useNavigate();
	const toast = useToast();
	const queryClient = useQueryClient();

	const editor = useEditor({
		extensions: [
			StarterKit,
			Mention.configure({
				HTMLAttributes: {
					class: 'mention',
				},
				suggestion: type === 'client' ? clientSuggestion : suggestion,
			}),
		],
		content: content
			? content
			: `
			<h2>Salut,</h2>
			<p>Asta este un exemplu <em>basic</em> de <strong>avertizare</strong>.</p>
			<ol>
				<li>Incepe sa modifici</li>
				<li>apoi apasa butonul de salvare.</li>
			</ol>
		`,
	});

	const handleSave = () => {
		const html = editor.getHTML();
		// console.log({ html: `${html}` });
		updateTemplate.mutate(
			{ statusId, mutationData: { html } },
			{
				onSuccess: () => {
					queryClient.invalidateQueries(['insurers', insurerId]);
					// handleCancel();
					toast({
						description: `Template salvat!`,
						status: 'success',
						duration: 5000,
						isClosable: true,
					});
				},
				onError: error => {
					toast({
						title: 'Eroare!',
						description: error.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				},
			}
		);
	};

	const handleCancel = () => {
		navigate(-1);
	};

	return (
		<VStack spacing={2} my={6} width={'100%'} alignItems={'flex-start'}>
			<Heading as={'h3'} size={'md'}>
				Mail {type === 'client' ? 'catre client' : 'avertizare'}
			</Heading>
			<MenuBar editor={editor} />
			<Box width={{ base: '100%', lg: '80%', xl: '60%' }}>
				<EditorContent editor={editor} />
			</Box>
			<Box>
				<Button onClick={handleCancel} mr={3}>
					Renunta
				</Button>
				<Button colorScheme={'blue'} onClick={handleSave}>
					Salveaza mail
				</Button>
			</Box>
		</VStack>
	);
};

export default RichTextEditor;
