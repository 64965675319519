import { Button, Flex } from '@chakra-ui/react';
import { Outlet, useNavigate } from 'react-router-dom';
import BackButton from '../components/common/basic/back-button';
import { useAuthState } from '../utils/firebase';

const AdminPage = () => {
	const { user } = useAuthState();
	const navigate = useNavigate();

	const handleNavigate = route => {
		navigate(route);
	};

	const handleBack = () => {
		navigate('/');
	};

	return (
		<Flex w={'100%'} h={'calc(100vh - 64px)'} borderTop={'1px'} borderColor={'gray.700'}>
			<Flex direction={'column'} w={'250px'} borderRight={'1px'} borderColor={'gray.700'}>
				<Button
					justifyContent={'flex-start'}
					variant={'ghost'}
					colorScheme={'blue'}
					mt={1}
					rounded={'none'}
					isFullWidth
					onClick={() => handleNavigate('/admin-panel/parts-nomenclature')}
				>
					Nomenclator piese
				</Button>
				<Button
					justifyContent={'flex-start'}
					variant={'ghost'}
					colorScheme={'blue'}
					mt={1}
					rounded={'none'}
					isFullWidth
					onClick={() => handleNavigate('/admin-panel/documents-status')}
				>
					Statusuri dosare
				</Button>
				<Button
					justifyContent={'flex-start'}
					variant={'ghost'}
					colorScheme={'blue'}
					mt={1}
					rounded={'none'}
					isFullWidth
					onClick={() => handleNavigate('/admin-panel/laborator-status')}
				>
					Status laborator
				</Button>
				{user.department === 'admin' ? (
					<Button
						justifyContent={'flex-start'}
						variant={'ghost'}
						colorScheme={'blue'}
						mt={1}
						rounded={'none'}
						isFullWidth
						onClick={() => handleNavigate('/admin-panel/users')}
					>
						Users
					</Button>
				) : null}
				<BackButton
					justifyContent={'flex-start'}
					rounded={'none'}
					size={'md'}
					colorScheme={'red'}
					onClick={handleBack}
				>
					Inapoi la aplicatie
				</BackButton>
			</Flex>
			<Flex flexGrow={1} direction={'column'} py={6} px={6} overflowY={'auto'} height={'100%'}>
				<Outlet />
			</Flex>
		</Flex>
	);
};

export default AdminPage;
