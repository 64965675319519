import { Button, Wrap } from '@chakra-ui/react';

export const MenuBar = ({ editor }) => {
	if (!editor) {
		return null;
	}

	return (
		<Wrap>
			<Button
				onClick={() => editor.chain().focus().toggleBold().run()}
				colorScheme={editor.isActive('bold') ? 'blue' : 'gray'}
			>
				Bold
			</Button>
			<Button
				onClick={() => editor.chain().focus().toggleItalic().run()}
				colorScheme={editor.isActive('italic') ? 'blue' : 'gray'}
			>
				Italic
			</Button>
			<Button
				onClick={() => editor.chain().focus().toggleStrike().run()}
				colorScheme={editor.isActive('strike') ? 'blue' : 'gray'}
			>
				Strike
			</Button>
			{/* <Button
				onClick={() => editor.chain().focus().toggleCode().run()}
				className={editor.isActive('code') ? 'is-active' : ''}
			>
				code
			</Button> */}

			{/* <Button onClick={() => editor.chain().focus().clearNodes().run()}>clear nodes</Button> */}
			<Button
				onClick={() => editor.chain().focus().setParagraph().run()}
				colorScheme={editor.isActive('paragraph') ? 'blue' : 'gray'}
			>
				Paragraf
			</Button>
			<Button
				onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
				colorScheme={editor.isActive('heading', { level: 1 }) ? 'blue' : 'gray'}
			>
				Titlu
			</Button>
			<Button
				onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
				colorScheme={editor.isActive('heading', { level: 2 }) ? 'blue' : 'gray'}
			>
				Subtitlu
			</Button>
			{/* <Button
				onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
				colorScheme={editor.isActive('heading', { level: 3 }) ? 'blue' : 'gray'}
			>
				Subtitlu2
			</Button>
			<Button
				onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
				colorScheme={editor.isActive('heading', { level: 4 }) ? 'blue' : 'gray'}
			>
				Subtitlu3
			</Button>
			<Button
				onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
				colorScheme={editor.isActive('heading', { level: 5 }) ? 'blue' : 'gray'}
			>
				Subtitlu4
			</Button>
			<Button
				onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
				colorScheme={editor.isActive('heading', { level: 6 }) ? 'blue' : 'gray'}
			>
				Subtitlu5
			</Button> */}
			<Button
				onClick={() => editor.chain().focus().toggleBulletList().run()}
				colorScheme={editor.isActive('bulletList') ? 'blue' : 'gray'}
			>
				Lista neordonata
			</Button>
			<Button
				onClick={() => editor.chain().focus().toggleOrderedList().run()}
				colorScheme={editor.isActive('orderedList') ? 'blue' : 'gray'}
			>
				Lista ordonata
			</Button>
			<Button onClick={() => editor.chain().focus().unsetAllMarks().run()}>Sterge formatare</Button>
			{/* <Button
				onClick={() => editor.chain().focus().toggleCodeBlock().run()}
				className={editor.isActive('codeBlock') ? 'is-active' : ''}
			>
				code block
			</Button> */}
			{/* <Button
				onClick={() => editor.chain().focus().toggleBlockquote().run()}
				colorScheme={editor.isActive('blockquote') ? 'blue' : 'gray'}
			>
				Citat
			</Button> */}
			{/* <Button onClick={() => editor.chain().focus().setHorizontalRule().run()}>horizontal rule</Button>
			<Button onClick={() => editor.chain().focus().setHardBreak().run()}>hard break</Button>
			<Button onClick={() => editor.chain().focus().undo().run()}>undo</Button>
			<Button onClick={() => editor.chain().focus().redo().run()}>redo</Button> */}
		</Wrap>
	);
};
