import { useFetchAddons } from '../state/server-side/queries/addons';
import { Alert, Badge, Box, Grid, Heading, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../components/common/basic/back-button';
import MainLayout from '../components/common/main-layout';
import LoadingPage from './loading';
import DeleteButton from '../components/addons/delete-button';
import { MdRefresh } from 'react-icons/md';

const AddonsPage = () => {
	const navigate = useNavigate();
	const { orderId } = useParams();

	const { data: addons, isLoading, refetch } = useFetchAddons(orderId);

	const handleBack = () => {
		navigate(`/${orderId}`);
	};

	// useEffect(() => {
	// 	console.log('addons', addons);
	// }, [addons]);

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<MainLayout alignItems={'start'}>
			<Grid mt={8} gap={6} minW={'400px'}>
				<Heading textAlign={'center'}>Lista suplimentari</Heading>
				{addons !== undefined ? (
					<Grid gap={2}>
						<Tooltip label="Reincarca suplimentari" hasArrow>
							<IconButton
								variant="ghost"
								size="lg"
								w="70px"
								h="70px"
								justifySelf={'end'}
								icon={<MdRefresh size="30" />}
								colorScheme="blue"
								isRound
								isLoading={isLoading}
								onClick={refetch}
							/>
						</Tooltip>
						{addons.map((addon, index) => {
							/**
							 * `item.date` is the addon request date which initializes
							 * at 01/01/1999, so if the year of `item.date` is 1999,
							 * it means the addon hasn't been requested yet.
							 */
							const notRequested = new Date(addon.date).getFullYear() === 1999;

							return (
								<Alert
									key={addon.id}
									minW={{ md: 700 }}
									status={addon.accepted ? 'success' : 'info'}
									w={'100%'}
									justifyContent={'space-between'}
									alignItems={'center'}
									mt={1}
									pl={4}
									//   mb={index === addons.length - 1 ? 8 : 0}
									fontSize={{ base: 'smaller', md: 'md' }}
								>
									<Box maxW={'50%'}>
										<Text>
											{addon.workType}{' '}
											<Text display={'inline-block'} fontWeight={'bold'}>
												{addon.part}
											</Text>
										</Text>
										<Box display={'flex'} mt={1} alignItems={'center'}>
											<Badge colorScheme="orange" fontSize="0.85rem" fontWeight="medium" px={2} py={1}>
												{addon.damageDescription}
											</Badge>
											<Text ml={2}>{addon.workType === 'REPARAT' && `${addon.time} ore`}</Text>
										</Box>
									</Box>
									<Box display={'flex'} alignItems={'center'}>
										{notRequested ? (
											<>
												<Badge fontSize="0.75rem" fontWeight="medium" mr={4} px={2} py={1}>
													NESOLICITAT
												</Badge>
												<DeleteButton
													addon={{ orderId, addonId: addon.id, type: addon.workType, part: addon.part }}
												/>
											</>
										) : addon.accepted ? (
											<Badge colorScheme="green" fontSize="0.85rem" fontWeight="medium" px={2} py={1}>
												ACCEPTAT {addon.accepted !== 1 && ` - ${addon.accepted} ore`}
											</Badge>
										) : (
											<Badge colorScheme="blue" fontSize="0.85rem" fontWeight="medium" px={2} py={1}>
												SOLICITAT
											</Badge>
										)}
									</Box>
								</Alert>
							);
						})}
					</Grid>
				) : null}
				<BackButton onClick={handleBack}>Inapoi la pagina principala</BackButton>
			</Grid>
		</MainLayout>
	);
};

export default AddonsPage;
