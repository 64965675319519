import { Box, Button, HStack, Input, Text, useToast } from '@chakra-ui/react';
import {
	useAddUserFurnizor,
	useDeleteFurnizor,
	useUpdateGestiuniFurnizor,
} from '../../../state/server-side/mutations/users';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import Select from 'react-select';
import { isEqual } from '../../../utils/helpers';

export const ListaFurnizori = ({ user }) => {
	const toast = useToast();
	const queryClient = useQueryClient();
	const [furnizor, setFurnizor] = useState('');
	const { furnizori, id, email, allDepozite } = user;
	const addFurnizor = useAddUserFurnizor();

	const hasFurnizori = furnizori?.length > 0;

	// console.log('u', user);

	function handleAddFurnizor() {
		addFurnizor.mutate(
			{ email, mutationData: { furnizori: hasFurnizori ? furnizori : [], crtFurnizor: furnizor, userId: id } },
			{
				onSuccess: data => {
					queryClient.invalidateQueries(['user', email]);
					toast({
						description: `Success!`,
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
				},
				onError: error => {
					toast({
						title: 'Eroare!',
						description: error.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				},
			}
		);
	}

	return (
		<>
			{hasFurnizori ? (
				<>
					{furnizori.map(item => (
						<FurnizorItem key={item.id} furnizor={item} allDepozite={allDepozite} user={{ id, email }} />
					))}
				</>
			) : (
				<Text>Acest user nu are niciun furnizor setat.</Text>
			)}
			<HStack maxW={'50%'} mt={4}>
				<Input
					type={'text'}
					placeholder={'Adauga un furnizor nou (Crt)'}
					value={furnizor}
					onChange={e => setFurnizor(e.target.value)}
				/>
				<Button my={4} onClick={handleAddFurnizor}>
					Adauga
				</Button>
			</HStack>
		</>
	);
};

function FurnizorItem({ furnizor, allDepozite, user }) {
	const updateGestiuniFurnizor = useUpdateGestiuniFurnizor();
	const deleteFurnizor = useDeleteFurnizor();
	const toast = useToast();
	const queryClient = useQueryClient();

	const _allDepozite = allDepozite.map(i => ({ label: i.name, value: i.id }));
	const gestiuni = furnizor.gestiuni.map(i => ({ label: i.name, value: i.id }));

	const [value, setValue] = useState(gestiuni);
	const [hasChanged, setHasChanged] = useState(false);

	function handleDeleteFurnizor() {
		deleteFurnizor.mutate(
			{ userId: user.id, furnizorId: furnizor.id },
			{
				onSuccess: data => {
					queryClient.invalidateQueries(['user', user.email]);
					toast({
						description: `Success!`,
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
				},
				onError: error => {
					toast({
						title: 'Eroare!',
						description: error.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				},
			}
		);
	}

	function saveGestiuni() {
		const newGestiuni = value.map(i => ({ id: i.value, name: i.label }));
		const newFurnizor = { ...furnizor, gestiuni: newGestiuni };

		// console.log('nf', newFurnizor);

		updateGestiuniFurnizor.mutate(
			{ userId: user.id, furnizor: newFurnizor },
			{
				onSuccess: data => {
					queryClient.invalidateQueries(['user', user.email]);
					toast({
						description: `Success!`,
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
					setHasChanged(false);
				},
				onError: error => {
					toast({
						title: 'Eroare!',
						description: error.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				},
			}
		);
	}

	function handleChange(e) {
		if (e.length === gestiuni.length) {
			const areEqual = isEqual(e, gestiuni);
			if (areEqual) {
				setValue(e);
				setHasChanged(false);
				return;
			}
			setValue(e);
			setHasChanged(true);
			return;
		}
		setValue(e);
		setHasChanged(true);
	}

	return (
		<Box backgroundColor={'blue.800'} maxW={'50%'} p={4} borderRadius={4} mb={2}>
			<HStack mb={4} justifyContent={'space-between'}>
				<Text>{furnizor.name}</Text>
				<Button colorScheme={'red'} onClick={handleDeleteFurnizor} isLoading={deleteFurnizor.isLoading}>
					Sterge furnizor
				</Button>
			</HStack>
			<Select
				value={value}
				onChange={handleChange}
				isMulti
				name="colors"
				options={_allDepozite}
				styles={{
					option: baseStyles => ({
						...baseStyles,
						color: 'black',
					}),
				}}
			/>
			{hasChanged ? (
				<Button mt={4} colorScheme={'blue'} onClick={saveGestiuni} isLoading={updateGestiuniFurnizor.isLoading}>
					Salveaza modificari
				</Button>
			) : null}
		</Box>
	);
}
