import { useMutation } from 'react-query';
import { getAuth } from '@firebase/auth';

const addManoperaTesla = async (orderId, type, formData) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${process.env.REACT_APP_API_URL}/${type}-tesla/${orderId}`, {
		method: 'POST',
		headers: { authorization: `Bearer ${token}` },
		body: formData,
	});

	const data = await res.json();

	if (data.error) throw new Error(data.error);

	return data;
};

export const useAddManoperaTesla = () => {
	return useMutation(({ orderId, type, formData }) => addManoperaTesla(orderId, type, formData));
};
