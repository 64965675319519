import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../components/common/basic/back-button';
import OrderDetails from '../../components/common/order-details';
import CurrentStatus from '../../components/order/file-status/current-status';
import NextStatus from '../../components/order/file-status/next-status';
import PrevStatus from '../../components/order/file-status/prev-status';
import { useFetchStatuses } from '../../state/server-side/queries/documents-status';
import { useFetchOrder } from '../../state/server-side/queries/order';
import DownloadFilesButton from './download-button';

const FileStatusPage = () => {
	const navigate = useNavigate();
	const { orderId } = useParams();
	const { data, isLoading } = useFetchStatuses(orderId);
	const { data: order, isLoading: isOrderLoading } = useFetchOrder(orderId);

	const handleBack = () => {
		navigate(-1);
	};

	if (isLoading || isOrderLoading) return <div>Loading...</div>;

	return (
		<>
			<Box d="flex">
				<Box mt={6} px={2} d={'flex'} w="80%" alignItems="center" justifyContent="flex-start">
					<BackButton onClick={handleBack}>Inapoi</BackButton>
					<DownloadFilesButton orderId={orderId} />
				</Box>
				<OrderDetails data={order} />
			</Box>
			<Flex mt={4} width={'100%'} justifyContent={'center'}>
				<Box width={'80%'}>
					{data.map(status => (
						<div key={status.id}>
							{
								// inactive status
								!status.startUser ? (
									<NextStatus name={status.name} />
								) : status.isFileUploaded ? (
									// finished status
									<PrevStatus
										name={status.name}
										filename={status.isFileUploaded}
										endUser={status.endUser}
										endDate={status.endDate}
									/>
								) : (
									<CurrentStatus
										id={status.id}
										name={status.name}
										startDate={status.startDate}
										deadline={status.deadline}
									/>
								)
							}
						</div>
					))}
				</Box>
			</Flex>
		</>
	);
};

export default FileStatusPage;
