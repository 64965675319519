import { Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';

const NextStatus = ({ name }) => {
	return (
		<Flex
			borderWidth={'thin'}
			borderRadius={'md'}
			bgColor={'whiteAlpha.50'}
			padding={4}
			mb={3}
			opacity={0.3}
			justifyContent={'space-between'}
			alignItems={'center'}
		>
			<Heading as={'h4'} size="md">
				{name}
			</Heading>
			<Text>Neinceput</Text>
		</Flex>
	);
};

export default NextStatus;
