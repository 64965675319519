import { IconButton, Tooltip } from '@chakra-ui/react';
import { MdCloudDownload } from 'react-icons/md';
import { useDownloadMutation } from '../../state/server-side/mutations/photos';
import { saveAs } from 'file-saver';

const DownloadButton = ({ type, orderId }) => {
	const mutation = useDownloadMutation();

	const handleClick = () => {
		mutation.mutate(
			{ type, orderId },
			{
				onSuccess: data => {
					saveAs(data, `poze_${orderId}_${type}.zip`);
				},
			}
		);
	};

	return (
		<Tooltip label="Download all" hasArrow>
			<IconButton
				variant="ghost"
				size="lg"
				w="70px"
				h="70px"
				icon={<MdCloudDownload size="30" />}
				colorScheme="blue"
				isRound
				isLoading={mutation.isLoading}
				onClick={handleClick}
			/>
		</Tooltip>
	);
};

export default DownloadButton;
