import { Box, useToast } from '@chakra-ui/react';
import { useUpdateSerieAvizIesire } from '../../../state/server-side/mutations/users';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import Select from 'react-select';

export const DocumentIesiri = ({ user }) => {
	const toast = useToast();
	const queryClient = useQueryClient();
	const { id, allSeriiAvizIesire, seriiDocumente } = user;
	const mutation = useUpdateSerieAvizIesire();

	const _allSeriiAvizIesire = allSeriiAvizIesire.map(i => ({ label: `${i.serie} - ${i.descriere}`, value: i.id }));

	const topDocumentAvizIesire = seriiDocumente?.avizIesire?.id
		? { label: seriiDocumente.avizIesire.label, value: seriiDocumente.avizIesire.id }
		: null;

	const [value, setValue] = useState(topDocumentAvizIesire);

	function handleChange(e) {
		const result = !e ? null : e.value;

		mutation.mutate(
			{ userId: id, idSerieAvizIesire: result },
			{
				onSuccess: data => {
					queryClient.invalidateQueries(['user', user.email]);
					toast({
						description: `Success!`,
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
					setValue(e);
				},
				onError: error => {
					toast({
						title: 'Eroare!',
						description: error.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				},
			}
		);
	}

	return (
		<>
			<Box backgroundColor={'blue.800'} maxW={'50%'} p={4} borderRadius={4} mb={2}>
				<Select
					value={value}
					onChange={handleChange}
					name="colors"
					options={_allSeriiAvizIesire}
					isClearable
					isLoading={mutation.isLoading}
					styles={{
						option: baseStyles => ({
							...baseStyles,
							color: 'black',
						}),
					}}
				/>
			</Box>
		</>
	);
};
