import { Route, Routes } from 'react-router-dom';
import { AuthRoute, UnauthRoute, useAuthState } from './utils/firebase';

import HomePage from './pages/home';
import LoginPage from './pages/login';
import OrderPage from './pages/order';
import LoadingPage from './pages/loading';
import PhotosPage from './pages/photos';
import GalleryPage from './pages/gallery';
import AddonsPage from './pages/addons';

import Navbar from './components/common/navbar';
import AdminRoutes from './routes/admin-panel/admin-panel';
import FileStatusPage from './pages/file-status/file-status';

const App = () => {
	const { initialising } = useAuthState();

	if (initialising) {
		return <LoadingPage />;
	}

	return (
		<>
			<Navbar />
			<Routes>
				<Route path="/admin-panel/*" element={<AdminRoutes />} />
				<Route path="/:orderId/file-status" element={<AuthRoute page={<FileStatusPage />} />} />
				<Route path="/:orderId/addons" element={<AuthRoute page={<AddonsPage />} />} />
				<Route path="/:orderId/gallery/:type" element={<AuthRoute page={<GalleryPage />} />} />
				<Route path="/:orderId/photos" element={<AuthRoute page={<PhotosPage />} />} />
				<Route path="/:orderId" element={<AuthRoute page={<OrderPage />} />} />
				<Route path="/" element={<AuthRoute page={<HomePage />} />} />
				<Route path="/login" element={<UnauthRoute page={<LoginPage />} />} />
			</Routes>
		</>
	);
};

export default App;
