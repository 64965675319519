import { useMutation } from 'react-query';

const { REACT_APP_API_URL: API_URL } = process.env;

const downloadPhotos = async (type, orderId) => {
	const res = await fetch(`${API_URL}/download/${type}/${orderId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	});

	if (!res.ok) throw new Error(`[downloadPhotos]: Server didn't respond, please try again.`);

	return await res.blob();
};

export const useDownloadMutation = () => {
	return useMutation(({ type, orderId }) => downloadPhotos(type, orderId));
};
