import { Avatar, Button, Flex, HStack, Text } from '@chakra-ui/react';
import { useAuthState } from '../../utils/firebase';
import { useLocation, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const { user, logout } = useAuthState();
  const location = useLocation();
  const navigate = useNavigate();
  const orderId = location.pathname.split('/')[1];

  const handleClick = () => {
    if (location.pathname === `/${orderId}`) {
      navigate('/');
    } else if (location.pathname.includes(`${orderId}`)) {
      navigate(`/${orderId}`);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Flex justify="space-between" m={3}>
      <HStack
        spacing={3}
        shouldWrapChildren
        style={{ cursor: 'pointer' }}
        onClick={handleClick}
      >
        <Avatar size="sm" name={user.email} />
        <Text>{user.email}</Text>
      </HStack>
      <Button colorScheme={'red'} variant={'ghost'} onClick={logout}>
        Logout
      </Button>
    </Flex>
  );
};

export default Navbar;
