import { Button, Flex, Heading, IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchInsurers } from '../../state/server-side/queries/documents-status';
import { FiPlus } from 'react-icons/fi';
import AddDocumentStatusDrawer from '../../components/admin/add-document-status';
import StatusList from '../../components/admin/status-list';
import { BsArrowLeft } from 'react-icons/bs';

const ConfigureInsurerStatuses = () => {
	const navigate = useNavigate();
	const { insurerId } = useParams();
	const { data, isLoading } = useFetchInsurers();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef();

	const insurer = data && data.find(insurer => insurer.id === +insurerId);

	const handleCloseEdit = () => {
		navigate(-1);
	};

	if (isLoading) return <div>Loading...</div>;

	return (
		<>
			<Flex justifyContent={'space-between'}>
				<Heading as={'h2'} mb={6}>
					<Tooltip label="Inapoi">
						<IconButton variant={'ghost'} icon={<BsArrowLeft />} mr={2} onClick={handleCloseEdit} />
					</Tooltip>
					Configurare {insurer.name}
				</Heading>
				<Button size={'lg'} leftIcon={<FiPlus />} onClick={onOpen}>
					Adauga status
				</Button>
				<AddDocumentStatusDrawer isOpen={isOpen} onClose={onClose} btnRef={btnRef} insurerId={insurerId} />
			</Flex>
			<StatusList insurerId={insurerId} />
		</>
	);
};

export default ConfigureInsurerStatuses;
