import { Heading } from '@chakra-ui/react';
import React from 'react';
import PartsNomenclatureForm from '../../components/admin/parts-nomenclature-form';

const PartsNomenclature = () => {
	return (
		<>
			<Heading as={'h2'} size={'lg'} mb={8}>
				Adauga piesa in nomenclator
			</Heading>
			<PartsNomenclatureForm />
		</>
	);
};

export default PartsNomenclature;
