import { useMutation } from 'react-query';
import { getAuth } from '@firebase/auth';

const { REACT_APP_API_URL: API_URL } = process.env;

const deleteAddon = async (orderId, addonId) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/addons/${orderId}`, {
		method: 'DELETE',
		headers: { authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
		body: JSON.stringify({ addonId }),
	});

	const data = await res.json();

	if (data.error) throw new Error(data.error);

	return data;
};

export const useDeleteAddonMutation = () => {
	return useMutation(({ orderId, addonId }) => deleteAddon(orderId, addonId));
};

const createPart = async (name, parent, hasDamage, hasChildren, user) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/parts`, {
		method: 'POST',
		headers: { authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
		body: JSON.stringify({ name, parent, hasDamage, hasChildren, user }),
	});

	const data = await res.json();

	if (data.error) throw new Error(data.error);

	return data;
};

export const useCreatePartMutation = () => {
	return useMutation(({ name, parent, hasDamage, hasChildren, user }) =>
		createPart(name, parent, hasDamage, hasChildren, user)
	);
};
