import { Alert, Grid, Text } from '@chakra-ui/react';

const OrderDetails = ({ data }) => {
  return (
    <Alert
      d={{ md: 'inline-block' }}
      status="info"
      variant="left-accent"
      maxW={['80%', null, 500]}
      mx={{ base: 'auto', md: 'unset' }}
      mr={{ md: 6 }}
      ml={{ md: 4 }}
      mt={6}
    >
      <Grid templateColumns="110px 1fr" alignItems="center">
        <Text>Crt deviz:</Text>
        <Text
          fontWeight="bold"
          color="blue.200"
          fontSize={{ base: 'md', md: 'xl' }}
        >
          {data.id}
        </Text>
        <Text>Numar deviz:</Text>
        <Text
          fontWeight="bold"
          color="blue.200"
          fontSize={{ base: 'md', md: 'xl' }}
        >
          {data.number}
        </Text>
        <Text>Nr. auto:</Text>
        <Text
          fontWeight="bold"
          color="blue.200"
          fontSize={{ base: 'md', md: 'xl' }}
        >
          {data.auto}
        </Text>
        <Text>Receptioner:</Text>
        <Text
          fontWeight="bold"
          color="blue.200"
          fontSize={{ base: 'md', md: 'xl' }}
        >
          {data.advisor}
        </Text>
      </Grid>
    </Alert>
  );
};

export default OrderDetails;
