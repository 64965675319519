import { useMutation } from 'react-query';
import { getAuth } from '@firebase/auth';

const { REACT_APP_API_URL: API_URL } = process.env;

const createDocumentStatus = async (insurerId, mutationData) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/documents-status/create/${insurerId}`, {
		method: 'POST',
		headers: { authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
		body: JSON.stringify(mutationData),
	});

	const data = await res.json();

	if (data.error) throw new Error(data.error);

	return data;
};

const updateDocumentStatus = async (statusId, mutationData) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/documents-status/update/${statusId}`, {
		method: 'PUT',
		headers: { authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
		body: JSON.stringify(mutationData),
	});

	const data = await res.json();

	if (data.error) throw new Error(data.error);

	return data;
};

const uploadFile = async (orderId, statusId, formData, notifyClient = 'true') => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${process.env.REACT_APP_API_URL}/file-status/${orderId}/${statusId}/${notifyClient}`, {
		method: 'POST',
		headers: { authorization: `Bearer ${token}` },
		body: formData,
	});

	const data = await res.json();

	if (data.error) throw new Error(data.error);

	return data;
};

const updateReminderTemplate = async (statusId, mutationData) => {
	const token = await getAuth().currentUser.getIdToken();
	// console.log({ mutationData });

	const res = await fetch(`${API_URL}/documents-status/reminder/${statusId}`, {
		method: 'PUT',
		headers: { authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
		body: JSON.stringify(mutationData),
	});

	const data = await res.json();

	if (data.error) throw new Error(data.error);

	return data;
};

const updateClientMailTemplate = async (statusId, mutationData) => {
	const token = await getAuth().currentUser.getIdToken();
	// console.log({ mutationData });

	const res = await fetch(`${API_URL}/documents-status/client-mail/${statusId}`, {
		method: 'PUT',
		headers: { authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
		body: JSON.stringify(mutationData),
	});

	const data = await res.json();

	if (data.error) throw new Error(data.error);

	return data;
};

const downloadDocuments = async orderId => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/file-status/${orderId}/download-all`, {
		headers: { authorization: `Bearer ${token}` },
	});

	if (!res.ok) {
		const error = await res.json();
		throw new Error(error.error);
	}

	return await res.blob();
};

const createRevenire = async (statusId, mutationData) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/revenire/${statusId}`, {
		method: 'PUT',
		headers: { authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
		body: JSON.stringify(mutationData),
	});

	const data = await res.json();

	if (data.error) throw new Error(data.error);

	return data;
};

const addSkipFlag = async (orderId, statusId) => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/skip/${orderId}/${statusId}`, {
		method: 'POST',
		headers: { authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
	});

	const data = await res.json();

	if (data.error) throw new Error(data.error);

	return data;
};

/**
 * @example
 * // data object:
 * const example = {
        "name": "aa",
        "deadline": "2",
        "deadlineUnit": "d",
        "mailToAdvisor": true,
        "mailToSuperior": true,
        "mailToClient": false,
        "reminderInterval": "i",
        "reminderIntervalTime": "2"
	}
 */
export const useCreateDocumentStatus = () => {
	return useMutation(({ insurerId, mutationData }) => createDocumentStatus(insurerId, mutationData));
};

export const useUpdateDocumentStatus = () => {
	return useMutation(({ statusId, mutationData }) => updateDocumentStatus(statusId, mutationData));
};

export const useUploadFile = () => {
	return useMutation(({ orderId, statusId, formData, notifyClient }) =>
		uploadFile(orderId, statusId, formData, notifyClient)
	);
};

export const useUpdateReminderTemplate = () => {
	return useMutation(({ statusId, mutationData }) => updateReminderTemplate(statusId, mutationData));
};

export const useUpdateClientMailTemplate = () => {
	return useMutation(({ statusId, mutationData }) => updateClientMailTemplate(statusId, mutationData));
};

export const useDownloadDocuments = () => {
	return useMutation(({ orderId }) => downloadDocuments(orderId));
};

export const useCreateRevenire = () => {
	return useMutation(({ statusId, mutationData }) => createRevenire(statusId, mutationData));
};

export const useAddSkipFlag = () => {
	return useMutation(({ orderId, statusId }) => addSkipFlag(orderId, statusId));
};
