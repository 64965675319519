import { Badge, Button, FormControl, FormErrorMessage, FormLabel, Heading, Input } from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const UsersPage = () => {
	const navigate = useNavigate();

	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
	} = useForm();

	function onSubmit(data) {
		navigate(`${data.email}`);
	}

	return (
		<>
			<Heading as={'h2'} size={'lg'} mb={8}>
				Users{' '}
				<a
					href={'https://docs.google.com/spreadsheets/d/1xonxJjmRo0FhvUydJZ0oXsl5PweT2S54zCTIXS0Ojr4/edit#gid=0'}
					target={'_blank'}
					rel="noreferrer"
				>
					<Badge p={2}>deschide lista useri</Badge>
				</a>
			</Heading>
			<form onSubmit={handleSubmit(onSubmit)} style={{ width: '33%' }}>
				<FormControl isInvalid={errors.email}>
					<FormLabel htmlFor="email">E-mail</FormLabel>
					<Input
						id="email"
						type="email"
						placeholder={'Cauta user'}
						{...register('email', {
							required: 'N-ai completat e-mailul!',
							pattern: {
								value:
									// eslint-disable-next-line
									/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: 'E-mailul trebuie sa fie sub forma: prenume.nume@dabauto.ro',
							},
						})}
					/>
					<FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
				</FormControl>

				<Button my={8} size={'lg'} colorScheme={'blue'} isLoading={isSubmitting} type="submit" isFullWidth>
					Editeaza permisiuni
				</Button>
			</form>
		</>
	);
};
