import { Heading } from '@chakra-ui/react';
import React from 'react';
import LaboratorDatepicker from '../../components/admin/laborator-datepicker';

const StatusLaboratorPage = () => {
	return (
		<>
			<Heading as={'h2'} size={'lg'} mb={8}>
				Status Laborator
			</Heading>
			<LaboratorDatepicker />
		</>
	);
};

export default StatusLaboratorPage;
