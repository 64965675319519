import { Box, useToast } from '@chakra-ui/react';
import { useUpdateDepoziteCustodie } from '../../../state/server-side/mutations/users';
import { useState } from 'react';
import Select from 'react-select';

export const DepoziteCustodie = ({ user }) => {
	const toast = useToast();
	const { id, allDepozite, depoziteCustodie } = user;

	const updateDepoziteCustodie = useUpdateDepoziteCustodie();

	const _allDepozite = allDepozite.map(i => ({ label: i.name, value: i.id }));
	const userDepozite = depoziteCustodie?.length > 0 ? depoziteCustodie.map(i => ({ label: i.name, value: i.id })) : [];

	const [value, setValue] = useState(userDepozite);

	function handleChange(e) {
		const _depozite = e.map(i => ({ id: i.value, name: i.label }));

		updateDepoziteCustodie.mutate(
			{ userId: id, depoziteCustodie: _depozite },
			{
				onSuccess: data => {
					toast({
						description: `Success!`,
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
					setValue(e);
				},
				onError: error => {
					toast({
						title: 'Eroare!',
						description: error.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				},
			}
		);
	}

	return (
		<Box backgroundColor={'blue.800'} maxW={'50%'} p={4} borderRadius={4} mb={2}>
			<Select
				value={value}
				onChange={handleChange}
				isMulti
				name="colors"
				options={_allDepozite}
				isLoading={updateDepoziteCustodie.isLoading}
				styles={{
					option: baseStyles => ({
						...baseStyles,
						color: 'black',
					}),
				}}
			/>
		</Box>
	);
};
