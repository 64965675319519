import { Box, Flex, Heading, HStack, IconButton, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useFetchInsurerData } from '../../state/server-side/queries/documents-status';
import { FiSettings } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const StatusList = ({ insurerId }) => {
	const navigate = useNavigate();
	const { data, isLoading } = useFetchInsurerData(insurerId);

	const handleClick = e => {
		navigate(`/admin-panel/documents-status/${insurerId}/edit/${e.currentTarget.ariaLabel}`);
	};

	if (isLoading) return <div>Loading...</div>;

	return (
		<HStack alignItems={'flex-start'}>
			{/* list */}
			<Box flex={1}>
				{data.map(status => (
					<Flex
						key={status.id}
						borderWidth={'thin'}
						borderRadius={'md'}
						bgColor={'whiteAlpha.50'}
						padding={4}
						mb={3}
						justifyContent={'space-between'}
						alignItems={'center'}
					>
						<Box>
							<Heading as={'h4'} size="md">
								{status.name}
							</Heading>
							<Text>Deadline: {status.deadline ? status.deadline + ' ' + status.deadlineUnit : 'Fara'}</Text>
							<Text>
								Mail catre: {status.mailToAdvisor && 'consultant'}
								{status.mailToSuperior && ', superior'}
								{status.mailToClient && ', client'}
							</Text>
						</Box>
						<Tooltip label="Editeaza status">
							<IconButton aria-label={status.id} icon={<FiSettings />} onClick={handleClick} />
						</Tooltip>
					</Flex>
				))}
			</Box>
		</HStack>
	);
};

export default StatusList;
