import { IconButton, Tooltip } from '@chakra-ui/react';
import { IoMdSettings } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const AdminButton = () => {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate('/admin-panel/parts-nomenclature');
	};

	return (
		<Tooltip label="Administrare" hasArrow>
			<IconButton
				pos={'absolute'}
				bottom={6}
				right={6}
				size={'lg'}
				w={'70px'}
				h={'70px'}
				icon={<IoMdSettings size="30" />}
				isRound
				onClick={handleClick}
			/>
		</Tooltip>
	);
};

export default AdminButton;
